.containerLogin {
    display: flex;
    height: 100vh;
    align-items: center;

    .titleLogin {
        margin: 0;
        color: #333;
    }

    .containerGlassLogin {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: 0 8px 32px 0 rgb(185 185 189 / 37%);
        backdrop-filter: blur(4.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border-radius: 10px;
    }

    .containerPrintDatosLogin {
        background: #fff;
        padding: 2rem;
    }

    .containerEmail-Password {
        display: flex;
        flex-direction: column;
    }

    .textLabel {
        text-align: left;
        font-weight: 500;
        color: #333;
    }

    .inputCustom {
        width: 100%;
        border: none;
        background: transparent;
        border-bottom: 1px solid #aaa;
        outline: none;
    }

    .relativePassword {
        position: relative;

        .viewPassword {
            position: absolute;
            top: 0;
            right: 1rem;
            color: #333;
            cursor: pointer;
        }
    }

    .resetWidth {
        width: 450px;
    }

    .link {
        width: auto;
        display: block;
        margin-inline: auto;
        color: #0d6efd;
        cursor: pointer;
    }
}