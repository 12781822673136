@use './menuDropdown.scss';

.container__btns__sidebar {
    width: 100%;
    position: fixed;
    background: #414141;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    padding: .4rem 0;
    justify-content: space-between;
    z-index: 6;

    @media (min-width:1024px) {
        position: inherit;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }

    @media (min-width:1024px) {
        display: none;
    }
}

.bgCloseSidebar {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    left: -100vw;
}

// Inicio contenedor sidebar
.sideBar {
    background: #fff;
    position: fixed;
    z-index: 2;
    height: 100vh;
    top: 0;
    width: 70vw;
    transition: all 0.5s;
    left: -100vw;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    overflow-y: auto;
    overflow-x: hidden;


    &[data-none="true"] {
        span {
            display: none;
        }
    }

    span {
        min-width: 170px;
    }

    ul {
        list-style-type: none;
        padding: 1rem;

        li {

            a {
                color: #333;
                font-weight: 500;
                text-decoration: none;
                display: grid;
                width: 100%;
                grid-template-columns: 35px auto;
                align-items: center;
                grid-template-rows: 60px;

                &:hover {
                    color: var(--color-orange);
                    transition: all 0.2s;
                }

                svg {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .closeSideBar {
        cursor: pointer;
        font-size: 20px;
    }

    @media (min-width:1024px) {
        width: 4rem;
        left: 0;
        background: #fff;
        box-shadow: 0 2px 7px 0 rgb(127 127 128 / 37%);
        backdrop-filter: blur(4px);
    }

    @media (max-width:1024px) {
        width: 14rem !important;

        span {

            display: block !important;
        }
    }
}

// Fin contenedor sidebar


.shadowCloseSidebar {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    width: 100vw;
    left: -100vw;
    cursor: pointer;
}

.w-3 {
    left: 0;
}


// Inicio transform burger
.menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    max-width: 65px;
    max-height: 35px;
    height: 80px;
    cursor: pointer;
    transition: all .5s ease-in-out;

    @media (min-width:769px) {
        display: flex;
    }

    .menu-btn__burger {
        width: 25px;
        height: 2px;
        background: var(--color-orange);
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
        transition: all .5s ease-in-out;

        &::before {

            content: "";
            position: absolute;
            width: 25px;
            height: 2px;
            background: var(--color-orange);
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
            transition: all .5s ease-in-out;
            transform: translateY(-8px);
        }

        &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 2px;
            background: var(--color-orange);
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
            transition: all .5s ease-in-out;
            transform: translateY(8px);
        }
    }
}

.borderLiNav {
    border-bottom: 1px solid #aaa;
    padding: 0.7rem 0;
}

.menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
}


.btnSidebar {
    svg {
        color: var(--color-orange);
        font-size: 1.5rem;
        cursor: pointer;

        &[data-arrow="true"] {
            color: var(--color-orange);
            transform: rotate(180deg);
        }
    }
}


.container__waves {
    background: #fff;
    text-align: center;

    .imgLogoGG {
        padding: 1rem;
    }

    .imgWaves {
        position: relative;
        bottom: -1px;
    }
}

.sub-menuAdmin {
    transition: ease-in-out .3s;
    margin-left: 1.5rem;
    position: relative;
    overflow: hidden;

    // height: 100vh;
    .sub-menuAdminLi {
        a {
            font-weight: 500;
            color: #333;
            text-decoration: none;
            display: grid;
            font-size: .9rem;
            width: 100%;
            grid-template-columns: 20px auto;
            align-items: center;
            grid-template-rows: 60px;
        }
    }
}

.btn-dropli {
    color: #333;
    font-weight: 500;
    text-decoration: none;
    display: grid;
    width: 100%;
    grid-template-columns: 35px auto;
    align-items: center;
    grid-template-rows: 60px;
    margin: 0;
    position: relative;
    cursor: pointer;

    svg {
        font-size: 1.5rem;
    }

    span {
        min-width: 170px;
    }

    .flechitaSubMenu {
        transition: ease-in-out .3s;
        position: absolute;
        right: 0;
    }
}

.rotateArrow {
    transform: rotate(90deg);
}

.subMenuOculto {
    height: 0;
}


.version {
    font-size: 0.8em;
    display: block;
    width: 100%;
    text-align: center;
}

.container-loading {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}