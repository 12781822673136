.btn-dangergg
{
    background-color: var(--color-danger);
    border: 1px solid var(--color-danger);
    color: #fff;
    border-radius: 15px;
    padding: 0.3rem;
    border: none;
    width: 250px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    text-align: center;
    height: 50px;
    width: 200px;
    outline: none;
    transition: color .3s;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover
    {
        background-color: transparent;
        color: var(--color-danger);
        border: 1px solid var(--color-danger);
    }
}
