#Player .listaTurnos {
    height: 70vh;
    min-height: 70vh;
    background: #EEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#Player .listaTurnos li {
    border: 1px solid #FFF;
    border-radius: 5px;
    margin-block: 12px;
    display: block;
    width: 100%;
    flex: 0 0 0 auto;
}