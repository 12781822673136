.container__map
{
    overflow: hidden;
    height: 50vh;

    iframe
    {
        width: 100%;
        height: 100%;
        box-shadow: 0px 1px 5px rgb(0 0 0 / 20%);
        border-radius: 8px;
    }
}
