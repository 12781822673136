.grid-custom-pendiente {
    display: grid;
    grid-template-columns: 20px 100px 150px 200px 250px 280px;
    padding: 1rem 0;
    border-bottom: 1px solid #aaa;
    align-items: center;

    @media (min-width:1367px) {
        grid-template-columns: 20px repeat(5, 1fr);
    }

    @media (min-width:1280px) and (max-width:1359px) {
        grid-template-columns: 20px 75px 125px 185px 250px 275px;
    }
}


.ballTurnoPendiente {
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &[data-color="0"] {
        background: var(--color-danger);
    }

    &[data-color="1"] {
        background: var(--color-orange);
    }

    &[data-color="2"] {
        background: var(--color-green-two);
    }
}

.infoTurno {
    margin-left: 0.5rem;
    margin-bottom: 0%;
    background: var(--color-danger);
    color: #fff;
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 12px;
    white-space:nowrap;

    &[data-color="0"] {
        background: var(--color-danger);
    }

    &[data-color="1"] {
        background: var(--color-orange);
    }

    &[data-color="2"] {
        background: var(--color-green-two);
    }
}

.grid-custom-pendiente .btnAtender,
.grid-custom-pendiente .btnCancelar,
.grid-custom-pendiente .btnDerivar,
.grid-custom-pendiente .btnDe,
.grid-custom-pendiente .btnConfirmar {
    width: auto;
    min-width: 80px;
}

.btnConfirmar {
    border: 1px solid var(--color-green-alternative);
    padding: 0.2rem;
    border-radius: 8px;
    margin: 0 .5rem;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;
    color: #333;

    &:hover {
        color: #fff;
        background-color: var(--color-green-alternative);
    }
}

.disabled {
    border-color: #AAA !important;
    color:#666!important;

    &:hover {
        color: #fff!important;
        background-color: #AAA!important;
    }
}

.btnAtender {
    border: 1px solid var(--color-green-two);
    padding: 0.2rem;
    border-radius: 8px;
    margin: 0 .5rem;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;
    color: #333;

    &:hover {
        color: #fff;
        background-color: var(--color-green-two);
    }
}

.btnCancelar {
    border: 1px solid var(--color-danger);
    padding: 0.2rem;
    border-radius: 8px;
    margin: 0 .5rem;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: var(--color-danger);
    }
}

.btnDerivar {
    border: 1px solid var(--color-orange);
    padding: 0.2rem;
    border-radius: 8px;
    margin: 0 .5rem;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: var(--color-orange);
    }
}

.btnEdit {
    border: 1px solid var(--color-orange);
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;
    color: var(--color-orange);
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: #fff;
        background-color: var(--color-orange);
    }
}

.btnAgregarTurno {
    border: 1px solid var(--color-gray);
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    transition: all .2s;
    width: 160px;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: var(--color-gray);
    }
}

.btnDe {
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    padding: 0.2rem;
    margin: 0 .5rem;
    background-color: transparent;
    transition: all .2s;
    font-size: .9rem;
    text-align: center;
    color: #333;

    &:hover {
        color: #fff;
        background-color: var(--color-gray);
    }
}


.btnPaginationAtras {
    background: none;
    border: none;
    margin: 0 1rem;
}

.btnPaginationAdelante {
    background: none;
    border: none;
    margin: 0 1rem;
}

.containerDatosPersonaCard {
    position: fixed;
    background: #fff;
    min-width: 450px;
    width: 40vw;
    z-index: 111;
    border-radius: 30px 0 0 30px;
    height: 100vh;
    top: 0;
    transition: ease-in-out .4s;
    right: -100vw;
    box-shadow: -1px 0px 12px rgb(0 0 0 / 20%);
    overflow-y: auto;
    padding: 2rem;
    display: flex;
    align-items: center;

    &[data-swap="on"] {
        right: 0;
    }
}

.shadowClose {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    width: 0;
    z-index: 2;
    height: 100vh;
    top: 0;
    right: 0;

    &[data-swap="on"] {
        width: 100vw;
    }
}

.btnPaginationAtras {
    &[data-disabled="on"] {
        opacity: .4;
        pointer-events: none;
    }
}

.btnPaginationAdelante {
    &[data-disabled="on"] {
        opacity: .4;
        pointer-events: none;
    }
}