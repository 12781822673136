.container_user_data {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .user_notification
    {
        margin-right: 1rem;
    }
}

.container_user_data h4 {
    font-size: 18px;
}

.container_user_data h6 {
    font-size: 16px;
    margin: 10px auto;
}
