:root
{
  --heeboFont: 'Heebo', sans-serif;
  --karlaFont: 'Karla', sans-serif;
  --color-green: #BCCB48;
  --color-green-two:#8fb435;
  --color-orange: #FF9013;
  --color-yellow:#FAB21E;
  --color-gray: #88868B;
  --color-danger: #FE4545;
  --color-green-alternative: #8fb435;
} 

a
{
  cursor: pointer;
}

body
{
  padding: 0 1rem;
}

html
{
  background: #fff;
}

.spanPendienteAtencion
{
  color: white;
  background: var(--color-orange);
  padding: 0.7rem;
  border-radius: 20px;
  font-size: .8rem;

  @media(max-width:1024px)
  {
    position: absolute;
    top: 1rem;
  }
}

.spanFinalizado
{
  color: white;
  background: var(--color-gray);
  padding: 0.7rem;
  border-radius: 20px;
  font-size: .8rem;

  @media(max-width:1024px)
  {
    position: absolute;
    top: 1rem;
  }
}

.spanAtendido
{
  color: white;
  background: var(--color-green-two);
  padding: 0.7rem;
  border-radius: 20px;
  font-size: .8rem;

  @media(max-width:1024px)
  {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.spanCancelado
{
  color: white;
  background: var(--color-danger);
  padding: 0.7rem;
  border-radius: 20px;
  font-size: .8rem;

  @media(max-width:1024px)
  {
    position: absolute;
    top: 1rem;
  }
}

#root
{
  background: #fff;
  min-height: 100vh;
  transition: all 0.5s;
  padding-left:5rem;
  
  @media (max-width:1024px)
  {
    padding-left: 0 !important;
  }
}

.fh5
{
  font-size: 1.1rem;
}

a{
  text-decoration: none;
}

.sideBar::-webkit-scrollbar {
  width: .2rem;
}
 
.sideBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.sideBar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  outline: 1px solid #aaa; 
}

.sideBar{
  scrollbar-color: #aaa rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;
}


.moveSidebar
{
  width: 14rem !important;
}

.moveRoot
{
  padding-left: 15rem !important;
}

.boldP
{
  font-weight:600;
  font-size: 13px;

  span
  {
    font-weight: 400;
  }
}

.accordion-button:not(.collapsed)
{
  color: #333;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus
{
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.resetInputs
{
  select,input
  {
    height: 55px;
    background: #EEEEEE;
    border-radius: 4px;

    &:focus
    {
      box-shadow: none;
      border-color: #aaa;
    }
  }

}


.btnSidebar
{
  @media (max-width:1024px)
  {
    display: none;
  }
}


.formatInputs
{
  border-radius: 8px;
  outline: none;
  border: none;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  padding: 0.7rem;
}

.searchInput
{
  border-radius: 20px;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  background: #E4E4E4;
  padding: 0.5rem 1rem;
}

.containerLoader
{
  position: fixed;
  background: rgba(0,0,0,0.9);
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1065;
  color: #fff;
}


textarea
{
  resize: none;
}


.notView
{
  opacity: .3;
  pointer-events: none;
}

.transitionThree
{
  transition: all .3s;
}

.buttonModal
{
    border-radius: 9px;
    border: 1px solid var(--color-orange);
    background: var(--color-orange);
    color: #fff;
    padding: .5rem;
    transition: ease-in-out .2s;
    outline: none;

    &:hover
    {
        background: transparent;
        color: var(--color-orange);    
    }
}

.buttonModal2
{
    border-radius: 9px;
    border: 1px solid var(--color-green);
    background: var(--color-green);
    color: #fff;
    padding: .5rem;
    transition: ease-in-out .2s;
    outline: none;

    &:hover
    {
        background: transparent;
        color: var(--color-green);    
    }
}

.containerAccionMasiva
{
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  border-bottom: 1px solid #aaa;
  align-items: center;
  transition: all .3s;

  h3
  {
    margin: 0;
    font-size: 1.3rem;
    color: #333;
    margin-right: 1rem;
  }
}

.notViewMasive
{
  opacity: .2;
  pointer-events: none;
}

.containerAccionMasiva .btnAtender, 
.containerAccionMasiva .btnCancelar, 
.containerAccionMasiva .btnDerivar, 
.containerAccionMasiva .btnDe, 
.containerAccionMasiva .btnConfirmar {
  width: auto;
  min-width: 80px;
}

.h-cu-800{
  height: 800px;
}