.listaTurnos {
    list-style: none;
    background-color: #FFF;
    padding: 20px;
}

.listaTurnos li {
    padding: 20px;
    background-color: #F2F2F2;
}

.containerInfo {
    display: flex;
    align-items: justify;
}

.listaTurnos li .cointainerTurnData {
    color: #212121;
    font-weight: bold;
    font-size: 1.3em;
    width:50%;
}

.listaTurnos li .containerBoxData {
    color: #212121;
    font-weight: bold;
    font-size: 1.2em;
    width:50%;
}