#loader
{
    position: fixed;
    background: rgba(0,0,0,0.55);
    width: 100vw;
    height: 100vh;
    z-index: 555;
    top: 0;
    display: flex;
    left: 0;
    justify-content: center;
    align-items: center;

    .loading
    {
        img
        {
            width: 80px;
            animation-name: loader;
            animation-iteration-count: infinite;
            animation-duration: 1.5s;
        }
    }
}

@keyframes loader 
{
    0%
    {
        transform: rotate(0);
    }
    100%
    {
        transform: rotate(360deg);
    }   
}