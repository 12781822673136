.select__custom {
    width: 100%;
    position: relative;
    margin: 1rem 0;

    @media (min-width:1200px) {
        width: 200px;
    }

    .select__btn {
        padding: 15px 20px;
        background: #fff;
        box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
        border-radius: 15px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            transition: all .5s;
            font-size: 1.5rem;
            color: var(--color-orange);
        }
    }

    .select__content {
        position: absolute;
        top: 110%;
        left: 0;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
        border-radius: 15px;
        font-weight: 500;
        color: #333;
        width: 100%;
        transition: all .5s;
        z-index: 6;
        height: 180px;
        overflow-y: auto;

        .select__item {
            padding: 10px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                background: #f4f4f4;
            }
        }
    }
}

.transformArrow {
    transform: rotate(180deg);
}
