.btn-greengg
{
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
    color: #fff;
    border-radius: 15px;
    padding: 0.3rem;
    width: 250px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    text-align: center;
    height: 50px;
    width: 200px;
    outline: none;
    transition: color .3s;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    &[data-disabled="on"]
    {
        background-color: #aaa;
        pointer-events: none;
        border: 1px solid #aaa;
        &::before
        {
            content: "";
            position: absolute;
            background: rgba(0,0,0,0.1);
            height: 100%;
            width: 90%;
            top: 0;
            transition: all .5s;
            left: 0;
            animation-name: chargeDisabled;
            animation-duration: 4s;
        }
    }

    &:hover
    {
        background-color: transparent;
        color: var(--color-green);
        border: 1px solid var(--color-green);
    }
}


@keyframes chargeDisabled 
{
    0%
    {
        width: 10%;
    }
    25%
    {
        width: 25%;
    }
    50%
    {
        width: 35%;
    }
    75%
    {
        width: 55%;
    }
    100%
    {
        width: 90%;
    }    
}
