.titlegray
{
  color: #333;
  font-size: 1.2rem;
  font-family: var(--karlaFont);
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button
  {
    border: 1px solid var(--color-gray);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 400;
    background-color: transparent;
    transition: all 0.2s;
    text-align: center;
    &:hover
    {
        color: #fff;
        background-color: var(--color-gray);
    }
  }
}

.titlegraySplash
{
  color: #333;
  font-size: 1.4rem;
  font-family: var(--karlaFont);
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  width: 100%;
}

.container__title
{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    
    img
    {
        margin-bottom: 1rem;
    }
}

.parrafoGray
{
  color: var(--color-gray);
  font-size: 1rem;
  font-family: var(--karlaFont);
  margin: 0;
  line-break: anywhere;
}

.fw-500
{
  font-weight: 500;
}

.input-gg
{
  border: none;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
  border-radius: 15px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  width: 100%;
  margin: 1.2rem 0;
  resize: none;

  &:focus
  {
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
  }

  .dropdown-container
  {
      width: 100%!important;
      outline: none;
      border: none;
      background-color: transparent;

      &:focus-within
      {
          border: none;
          box-shadow: none;
      }
  }
}

.mediaW100
{
  @media (max-width:1200px)
  {
    width: 100%;
  }
}

.grid-custom
{
  display: grid;
  grid-template-columns: repeat(1,1fr);

  @media (min-width:1200px) 
  {
    grid-template-columns: repeat(2,1fr);
  }

  @media (min-width:1400px) 
  {
    grid-template-columns: repeat(3,1fr);
  }
}

.gridQuestions
{
  display: grid;
  grid-template-columns: repeat(4,1fr);
}

.relativeQuestion
{
  position: relative;
  width: 90%;

  .btnCancelRespuesta
  {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.btnDropDownTables
{
  background: var(--color-green-two);
  border: 1px solid var(--color-green-two);
  border-radius: 30px;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  &:hover
  {
    color: var(--color-green-two);
    background: transparent;
    border: 1px solid var(--color-green-two);
  }

  &:focus
  {
    box-shadow: none;
  }

  &:active
  {
    background: var(--color-green-two);
    border-color: var(--color-green-two);
  }
}

.h-60format
{
  height: 55px;
}

.pFormatTurnos
{
  margin: 0;
  width: 180px;
  text-align: center;
}

.container__sucursal__box
{
  display: flex;
  align-items: center;
  color: var(--color-gray);

  p
  {
    margin: 0;
  }

  b
  {
    font-weight: 600;
    color: var(--color-green-alternative);
  }

  button
  {
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    font-weight: 600;
    background-color: transparent;
    transition: all 0.2s;
    width: 40px;
    text-align: center;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-left: 1.3rem;

    &:hover
    {
      color: #fff;
      background-color: var(--color-gray);
    }

    &:focus
    {
      box-shadow: none;
    }
  }
}