.container__form__observaciones {
    .customArea {
        resize: none;
        height: 200px;

        &:focus {
            border-color: #eee;
            box-shadow: none;
        }
    }
}

.bordersCustom {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding: 1rem 0;
    margin: 1rem 0;
}

.modal-backdrop {
    z-index: 2;
}

.modal {
    background-color: rgba(0, 0, 0, 0.3);
}


.cerrarModal {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 25px;
}