
.menu{
  border-radius:0.5rem;
  a{
    color: var(--color-yellow);
    text-decoration: none;
    font-weight: 300;
    &:focus,
    &:focus-within{
      outline:none;
    }
  }
  > ol{
    display:flex;
    > li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .ballNotif
      {
        width: 10px;
        height: 10px;
        background: var(--color-danger);
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        bottom: 1.15rem;
        left: 0.7rem;
        transition: all .2s;

        &[data-notificacion="off"]
        {
          opacity: 0;
        }
      }
    }
  }
}
@media(min-width: 45rem){
  .menu{
    > ol{
      justify-content: space-around;
      &:hover,
      &:focus-within{
        > li:first-child{
          > a{
              opacity:0.6;
          }
        }
      }
      > li {
        flex: 1 1 0;
        &:last-child:after {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          bottom: 0;
          margin-right: -50%;
          border-radius: 0.5rem;
          z-index: 0;    
          transition: 350ms cubic-bezier(1, 0.49, 0.09, 1.29) all;
          pointer-events: none;
        }
        @for $i from 1 to 12 {
          &:first-child:nth-last-child( #{$i} ) ~ li:last-child:after{          
                right: 50% + 100% * ($i - 1);
            }
        }
        @for $i from 1 to 12 {
          &:first-child:nth-last-child( #{$i} ),
          &:first-child:nth-last-child( #{$i} ) ~ li{          
            @for $j from 1 to $i {
              &:nth-child( #{$j} ):hover,
              &:nth-child( #{$j} ):focus,
              &:nth-child( #{$j} ):focus-within{     
                ~ li:last-child:after{
                  right: 50% + 100% * ($i - $j);
                }
                > ol a{
                  box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0), inset 0 0 0 3rem rgba(237, 110, 160, (1 - calc(1 / $i)*$j));
                  &:hover,
                  &:focus,
                  &:focus-within{
                  box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0.1), inset 0 0 0 3rem rgba(237, 110, 160, (1 - calc(1 / $i)*$j));
                  }
                }
              } 
              &:last-child:hover:after,
              &:last-child:focus:after,
              &:last-child:focus-within:after{
                right: 50%;
              }
            }
          }
        }
        &:first-child{
          > a{
              opacity:1;
          }
        }
        > a{
          font-size: 1.3rem;
          position: relative;
          width: 100%;
          text-align: center;
          z-index: 1;
          opacity:0.7;
          transition: 250ms ease all;
        }
        > ol{
          position:absolute;
          top:100%;
          right:0;
          width: 270px;
          list-style-type: none;
          padding: 0;
          z-index: 222;
          visibility: hidden;
          > li{
            margin-top:0.5rem;
            transition: 250ms cubic-bezier(0.42, 0.83, 0.49, 1.35) transform;
            transform: scale(0);
            > a{            
              display: block;
              text-align: left;
              border-radius: 0.2rem;
              box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
              padding: 1rem 0.5rem;
              font-size: .85rem;
              color: #333;
              transition: 250ms ease all;
              font-weight: 500;
              background: #fff;
              h3
              {
                font-size: 1.1rem;
                color: var(--color-green-alternative);
                position: relative;
                .ballNotifHijo
                {
                  background: var(--color-danger);
                  border-radius: 50%;
                  height: 10px;
                  right: 0;
                  position: absolute;
                  transition: all .2s;
                  width: 10px;
                  z-index: 2;

                  &[data-notificacion="off"]
                  {
                    opacity: 0;
                  }
                }
              }
              p
              {
                margin: 0;
                margin-top: 0.5rem;
                display: flex;
                justify-content: space-between;
              }
              &:hover,
              &:focus,
              &:focus-within{
                box-shadow: none;
              }
            }
          }
        }
        &:focus,
        &:focus-within{
            > a{
              opacity: 1 !important;
            }
            > ol{    
              visibility: visible;  
              > li{
                transform: scale(1);
                &:first-child{
                  transition-delay: 150ms;
                }
              }
              @for $k from 1 to 12 {
                > li:nth-child( #{$k} ){ 
                  transition-delay: 150ms * $k;
                }
              }
            }
        }
      }
    }
  }
}
@media(max-width: 45rem){
  .menu{
    a{
      font-size:1.4rem;
      padding: 1rem 0.5rem;
    }
    > ol{
      flex-direction: column;
      > li {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        > a:nth-last-child(2){
          position:relative;
          &:after{
            content:'';
            position:absolute;
            top: 50%;
            margin-top: -0.25rem;
            right: 1.5rem;
            border-top: 0.5rem solid #fff;
            border-left: 0.3rem solid transparent;
            border-right: 0.3rem solid transparent;
            transition: 250ms ease all;
          }
        }
        &:focus-within,
        &:focus,
        > a:hover,
        > a:focus-within,
        > a:focus{
          &:nth-last-child(2){
            &:after{
              transform: rotate(180deg);
            }
          }
          + ol{
            max-height: 12rem;
          }
        }
        > ol{
          background: rgba(0,0,0,0.1);
          box-shadow: inset 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
          overflow:hidden;
          max-height:0;
          transition: 250ms ease all;
          > li{
            a{
              display:block;
              font-size:1.2rem;
            }
          }
        }
      }
    }
  }
}