.gridMetricas
{
    display: grid;
    grid-template-columns: 120px 300px 100px;
    justify-content: space-between;
    align-items: center;
    .progress-bar
    {
        background-color: var(--color-green);
    }
    h3
    {
        line-break: anywhere;
    }
}
.loadingTableMetricas{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 40vh;

}
.divTotalTurnos
{
    display: flex;
    // flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;

    .selectViewTurnos
    {
        // position: absolute;
        // top: 0%;
        // left: 0;

        .divSelect
        {
            // display: flex;
            // justify-content: flex-end;
            // align-items: baseline;

            select
            {
                outline: none;
                border: none;
                background: transparent;
                cursor: pointer;
            }
        }
    }
}

.containerNumberTurnos
{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    
    p
    {
        font-size: 5rem;
        margin: 0;
        font-weight: 500;

        span
        {
            font-size: 1rem;
        }
    }
}

.highcharts-credits
{
    display:none;
}


.container__highcharts
{
    display: flex;
    background: #fff;
    padding: 1rem;
}
