.multiSelectStyles
{
    height: 38px;
    background: #EEEEEE;
    border-radius: 4px;
    border: 1px solid #ced4da;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    width: 100%;
    
    .dropdown-container
    {
        width: 100%!important;
        outline: none;
        border: none;
        background-color: transparent;

        &:focus-within
        {
            border: none;
            box-shadow: none;
        }
    }

    .gray
    {
        color: #333;
    }
}
