.styleCard
{
    display: flex;
    background: #fff;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);
    margin: 1rem;
    padding: 1rem;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .datos-card
    {
        padding-top: 5rem;
        min-height: 310px;
    }

    .imagen-card
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .itemHorario
    {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .numeroHorario
    {
        width: 100px;
        position: absolute;
        background: var(--color-green);
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%);
        height: 50px;
        margin: 0;
        display: flex;
        right: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 500;
    }
}

.ballHorario
{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    border-radius: 50%;

    &[data-color="0"]
    {
        background: red;
    }

    &[data-color="1"]
    {
        background: #FAB21E;
    }

    &[data-color="2"]
    {
        background: green;
    }
}


.relativeSpanCard
{
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.grid-card-colaborador
{
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto 405px;
    align-items: center;
    border-bottom: 1px solid #aaa;

    p
    {
        margin: 0;
        text-align: start;
    }
}
