.response {
    display: flex;
    flex-direction: row;
}

.response .respuesta {
    padding: 15px 20px;
    border: 1px solid #212121;
    margin: 5px 15px;
    cursor: pointer;
}