.viewPassword {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;

}

.inputTypePassword {
    margin-right: 5px;
}

.ojito {
    padding: 13px 10px;
    border-radius: 9px;
    cursor: pointer;
}